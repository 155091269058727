import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Span, Box, List, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiPerson } from "react-icons/gi";
import { AiTwotonePropertySafety, AiOutlineArrowUp } from "react-icons/ai";
import { IoIosHourglass, IoIosPeople, IoIosGlobe } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | DriveRight
			</title>
			<meta name={"description"} content={"Ласкаво просимо до \"DriveRight\" - Ваш шлях до впевненості!\nМи віримо, що навчання має бути простим, ефективним і приємним. Навчання водінню має бути плавною подорожжю без зайвих витребеньок та відволікань. "} />
			<meta property={"og:title"} content={"Головна | DriveRight"} />
			<meta property={"og:description"} content={"Ласкаво просимо до \"DriveRight\" - Ваш шлях до впевненості!\nМи віримо, що навчання має бути простим, ефективним і приємним. Навчання водінню має бути плавною подорожжю без зайвих витребеньок та відволікань. "} />
			<meta property={"og:image"} content={"https://blasterspace.live/images/bg.jpg"} />
			<link rel={"shortcut icon"} href={"https://blasterspace.live/images/favicon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blasterspace.live/images/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blasterspace.live/images/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blasterspace.live/images/favicon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blasterspace.live/images/favicon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blasterspace.live/images/favicon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://blasterspace.live/images/favicon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 900 56px/1.2 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					DriveRight - курси автомайстерності
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--base"
					font-size="20px"
					font-weight="300"
					color="--darkL2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
				>
					Ми віримо, що навчання має бути простим, ефективним і приємним. Навчання водінню має бути плавною подорожжю без зайвих витребеньок та відволікань. Наш приземлений підхід відрізняє нас від інших. Ось що робить нас унікальними:
				</Text>
				<List
					margin="20px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="gi"
							icon={GiPerson}
							size="20px"
							color="--primary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--greyD2" font="18px/27px --fontFamily-sans">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Персоналізоване навчання:{"  "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Ми не віримо в універсальні уроки. Ваша подорож з нами пристосована до ваших потреб, темпу та комфорту. Наші досвідчені інструктори працюють разом з вами над створенням персоналізованої навчальної програми, яка гарантує, що ви будете повністю готові до майбутньої подорожі.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="ai"
							icon={AiTwotonePropertySafety}
							size="20px"
							color="--primary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--darkL2" font="18px/27px --fontFamily-sans">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Перевірені часом методики:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Хоча ми цінуємо інновації, ми також цінуємо традиції. Наші методи навчання перевірені часом, з акцентом на оволодіння основами безпечного та відповідального водіння. Ніяких вигадливих гаджетів чи трюків, лише надійні навички, на які ви можете покластися.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="ai"
							icon={AiOutlineArrowUp}
							size="20px"
							color="--primary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--darkL2">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Досвід у дорозі:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--greyD2"
							>
								Наші інструктори не обмежуються базовими знаннями. Ми надаємо реальний досвід, щоб ви були впевнені в різних умовах водіння, від міських вулиць до автомагістралей.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image src="https://blasterspace.live/images/image1.jpg" display="block" border-radius="32px" />
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
			<Text
				margin="0px 0px 90px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Наші переваги:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Досвідчені інструктори
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Наша команда досвідчених та сертифікованих інструкторів з ентузіазмом навчає безпечному водінню. Вони привносять в кожен урок багатство знань і досвіду, гарантуючи, що ви отримаєте найкращу освіту з усіх можливих.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Персоналізоване навчання
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Ми віримо, що кожен учень унікальний, і саме тому наші інструктори адаптують своє навчання до ваших конкретних потреб. Незалежно від того, чи ви новачок, чи прагнете вдосконалити свої навички, ми створимо індивідуальний навчальний план саме для вас.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Гнучкий розклад
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Ваш час є цінним. Саме тому ми пропонуємо гнучкі варіанти розкладу, щоб пристосуватися до вашого зайнятого життя. Ми підберемо час занять, який підходить саме вам.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Statistics-14">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 10px 0px" text-align="center" font="normal 500 56px/1.2 --fontFamily-serifTimes">
				Чому варто обрати DriveRight
			</Text>
			<Text
				margin="0px 250px 70px 250px"
				text-align="center"
				font="--base"
				color="#454c52"
				lg-margin="0px 100px 70px 100px"
				md-margin="0px 0 50px 0"
			>
				Ми в DriveRight розуміємо, що у вас є вибір, коли справа доходить до вибору автошколи. Тож, чому ви повинні обрати нас?
Ми вирізняємося тим, що пропонуємо персоналізований та безстресовий процес навчання. Ми ставимо на перше місце ваші індивідуальні потреби та комфорт, роблячи вашу подорож до впевненого водіння приємною. Наша прихильність до прозорості, реальний досвід та досвідчені інструктори гарантують, що ви отримаєте першокласну освіту на кожному етапі навчання.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 24px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="36px 24px"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					align-items="center"
					flex-direction="column"
				>
					<Icon
						category="io"
						icon={IoIosHourglass}
						size="64px"
						margin="0px 0px 15px 0px"
						color="--primary"
					/>
					<Text margin="0px 0px 0px 0px" font="--lead" color="#454c52">
						Працюємо з
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 500 64px/1.2 --fontFamily-serifGaramond">
						2018
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Icon
						category="io"
						icon={IoIosPeople}
						size="64px"
						margin="0px 0px 15px 0px"
						color="--primary"
					/>
					<Text margin="0px 0px 10px 0px" font="normal 500 64px/1.2 --fontFamily-serifGaramond">
						45
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="#454c52">
						Досвідчених Інструкторів
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Icon
						category="io"
						icon={IoIosGlobe}
						size="64px"
						margin="0px 0px 15px 0px"
						color="--primary"
					/>
					<Text margin="0px 0px 10px 0px" font="normal 500 64px/1.2 --fontFamily-serifGaramond">
						2700+
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="#454c52">
						Випускників
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});